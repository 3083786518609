import {
  Typography,
  Box,
  Button,
  Link,
  Grid,
  List,
  ListItem,
  Card,
  CardContent,
  Stack,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../../lib/hooks/useAuth";
import { listMenu } from "../../../../components/UI/AdminSideNavigation";
import { PageLink } from "../../../../components/custom/PageLink";

const menuItems = listMenu.filter((item) => !["Home"].includes(item.title));

export function UserDashboardHome() {
  const { user } = useAuth();
  return (
    <Box>
      <Typography variant="h1">Dashboard</Typography>
      <Typography variant="body1">This is your home page</Typography>

      <Box mt={2}>
        <Stack
          direction="row"
          spacing={2}
        >
          {listMenu.map((menu) => {
            return (
              <PageLink
                key={menu.title}
                href={menu.link}
              >
                <Card sx={{ height: 200, width: 200 }}>
                  <CardContent
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <menu.icon sx={{ fontSize: 40 }} />
                    <Typography
                      variant="h4"
                      sx={{ mt: 1 }}
                    >
                      {menu.title}
                    </Typography>
                  </CardContent>
                </Card>
              </PageLink>
            );
          })}
        </Stack>
      </Box>
      <Outlet />
    </Box>
  );
}
