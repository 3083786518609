import { useDispatch } from "react-redux";
import {
  selectOrganizationById,
  selectOrganizations,
  setOrganizations,
} from "../../store/reducers/organizationSlice";
import { useSelector } from "react-redux";
import { bbBackendApi } from "../api/api";
import { apiRoutes } from "../constants/apiRoutes";
import type { TOrganization } from "../types";

type TUseOrganization = {
  organizationId?: string;
};
export function useOrganization({ organizationId }: TUseOrganization = {}) {
  const dispatch = useDispatch();
  const organizations = useSelector(selectOrganizations);
  const organization = useSelector(
    selectOrganizationById(organizationId ?? "")
  );

  function getAllOrganizations() {
    return bbBackendApi
      .get(apiRoutes.organization)
      .then((response) => {
        dispatch(setOrganizations(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  function createOrganization(organization: Partial<TOrganization>) {
    return bbBackendApi
      .post(apiRoutes.organization, organization)
      .then((response) => {
        console.log(response);
        getAllOrganizations();
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  }

  function updateOrganization(
    id: string,
    organization: Partial<TOrganization>
  ) {
    return bbBackendApi
      .put(`${apiRoutes.organization}/${id}`, organization)
      .then((response) => {
        console.log(response);
        getAllOrganizations();
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  }

  function deleteOrganization(id: string) {
    return bbBackendApi
      .delete(apiRoutes.organization + `/${id}`)
      .then((response) => {
        console.log(response);
        getAllOrganizations();
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  }

  return {
    organizations,
    organization,
    getAllOrganizations,
    createOrganization,
    updateOrganization,
    deleteOrganization,
  };
}
