import { useDispatch } from "react-redux";
import { selectOrganizationById } from "../../store/reducers/organizationSlice";
import { useSelector } from "react-redux";
import { bbBackendApi } from "../api/api";
import { apiRoutes } from "../constants/apiRoutes";
import type { TProject } from "../types";
import {
  selectProjectById,
  selectProjects,
  setProjects,
} from "../../store/reducers";

type TUseProjects = {
  projectId?: string;
};
export function useProjects({ projectId }: TUseProjects = {}) {
  const dispatch = useDispatch();
  const list = useSelector(selectProjects);
  const data = useSelector(selectProjectById(projectId ?? ""));

  function getAllProjects() {
    return bbBackendApi
      .get(apiRoutes.projects)
      .then((response) => {
        dispatch(setProjects(response.data));
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        dispatch(setProjects([]));
        return [];
      });
  }

  function createProject(organization: Partial<TProject>) {
    return bbBackendApi
      .post(apiRoutes.projects, organization)
      .then((response) => {
        console.log(response);
        getAllProjects();
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return { error: error.response.data.message };
      });
  }

  return {
    list,
    data,
    getAllProjects,
    createProject,
  };
}
