import { Container, Drawer } from "@mui/material";
import { AddNewJobForm } from "../jobs/AddNewJobForm";
import { ModalClose } from "../custom/ModalClose";
import type { FC } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  width?: string | number;
  anchor?: "top" | "left" | "bottom" | "right";
};

export const SideDrawer: FC<Props> = ({
  open,
  onClose,
  children,
  width,
  anchor,
}: Props) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor ?? "right"}
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            md: width ?? 400,
          },
        },
      }}
    >
      <Container maxWidth="lg">
        <ModalClose onClose={onClose} />
        {children}
      </Container>
    </Drawer>
  );
};
