import axios from "axios";

const bbBackedApiUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.nadhirsha.com/"
    : "http://localhost:8080/";

const bbBackendApi = axios.create({
  baseURL: bbBackedApiUrl,
});

bbBackendApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

bbBackendApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle error
    if (error.response) {
      // The request was made and the server responded with a status code
      // outside of the range of 2xx
      console.error("Response error status:", error.response.status);
      console.error("Response error data:", error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Request error:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);

export { bbBackendApi };
