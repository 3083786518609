import {
  DataGrid,
  GridToolbarContainer,
  useGridApiContext,
} from "@mui/x-data-grid";
import { useOrganization, type TOrganization } from "../../../lib";
import { useRef } from "react";
import { Button, Card, IconButton, Tooltip } from "@mui/material";
import {
  DeleteOutline,
  ViewAgendaOutlined,
  Visibility,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

type Props = {
  data: TOrganization[];
};

function CustomToolbar() {
  const { deleteOrganization } = useOrganization();
  const apiRef = useGridApiContext();

  const selectedItems = apiRef.current.getSelectedRows();

  const selectedItemsCount = selectedItems.size;

  function handleDelete() {
    const keys: string[] = [];

    selectedItems.forEach((key) => keys.push(key.id));

    deleteOrganization(keys.join(","));
    // console.log(selectedItems.forEach((key) => deleteOrganization(key.id)));
    // deleteOrganization(
    //   (selectedItems.keys().next().value as string[]).join(",")
    // );
    // deleteOrganization();
  }

  return (
    <GridToolbarContainer
      sx={{ display: "flex", justifyContent: "flex-end", padding: 1 }}
    >
      <Tooltip title="Delete">
        <Button
          disabled={selectedItemsCount === 0}
          onClick={handleDelete}
          startIcon={<DeleteOutline />}
        >
          Delete
        </Button>
      </Tooltip>
    </GridToolbarContainer>
  );
}

export const OrganizationsTable = ({ data }: Props) => {
  const navigate = useNavigate();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "phone",
      headerName: "Phone",
    },
    {
      field: "address",
      headerName: "Address",
    },
    {
      field: "website",
      headerName: "Website",
    },
    {
      field: "contactPerson",
      headerName: "Contact Person",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      width: 120,
      renderCell: (params: any) => {
        return (
          <Tooltip title="View">
            <IconButton
              onClick={() => {
                console.log(params.row);
                // navigate(`/organizations/${params.row.id}`);
              }}
            >
              <VisibilityOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];
  function handleSelectionModelChange(selectionModel: any) {
    // console.log(selectionModel);
  }

  function handleRowClick(params: any) {
    console.log(params.row);
  }

  function handleRowDoubleClick(params: any) {
    console.log("double click", params.row);
    navigate(`/dashboard/organizations/${params.row.id}`);
  }
  return (
    <Card>
      <DataGrid
        rows={data}
        getRowId={(row) => row.id}
        columns={columns}
        onRowDoubleClick={handleRowDoubleClick}
        checkboxSelection
        autoHeight
        onRowClick={handleRowClick}
        onRowSelectionModelChange={handleSelectionModelChange}
        slots={{ toolbar: CustomToolbar }}
        style={{ border: "none" }}
      />
    </Card>
  );
};
