import { Box, Card, CardContent, Grid, Input, Typography } from "@mui/material";
import useFileUploader from "../../../lib/hooks/useFileUploader";
import { useState } from "react";

export function TestFileUploader() {
  const { uploadFile, selectedFile, setSelectedFile } = useFileUploader();

  function handleFileUpload() {
    uploadFile();
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(event.target.files);
    setSelectedFile(event.target.files?.[0] || null);
  }
  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="h2">File uploader</Typography>
            <Typography variant="body1">
              This is a file uploader component
            </Typography>
            <Input
              type="file"
              onChange={handleFileChange}
            />
            <button onClick={handleFileUpload}>Upload</button>
          </Grid>
          <Grid item>
            <Box sx={{ p: 2 }}>
              {selectedFile && selectedFile.type.includes("image") && (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Selected File"
                  style={{ width: "200px", margin: "auto" }}
                />
              )}
              <Typography variant="body2">
                {selectedFile?.name || "No file selected"}
              </Typography>
              <Typography variant="body2">
                {selectedFile?.size
                  ? `${(selectedFile.size / 1024 / 1024).toFixed(2)} MB`
                  : "No file selected"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
