import {
  AppRegistrationOutlined,
  DashboardCustomizeOutlined,
  Login,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useAuth } from "../../../lib/hooks/useAuth";
import { useDispatch } from "react-redux";
import { setAuthDrawerIsOpen } from "../../../store/reducers";

// # styled
const HeroTitle = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  fontWeight: 800,
  textTransform: "uppercase",
  color: theme.palette.primary.main,
  // textAlign: "center",
  lineHeight: 0.8,
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    // textAlign: "right",
    fontSize: "4rem",
  },
}));

const IntroText = styled(Typography)(({ theme }) => ({
  fontSize: ".9rem",
  color: theme.palette.text.primary,
}));

// #
export function HomeLandingHero() {
  const { user } = useAuth();
  const dispatch = useDispatch();

  return (
    <Container>
      <Grid
        container
        sx={{ minHeight: 600, alignItems: "center" }}
      >
        <Grid
          item
          xs={12}
          md={12}
        >
          <Box sx={{}}>
            <HeroTitle>DASHBOARD</HeroTitle>
            <IntroText>Login to access the featured dashboard</IntroText>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
