import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import {
  useOrganization,
  type TOrganization,
  type TProject,
} from "../../../lib";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Pagination,
  PaginationItem,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ContentCopyOutlined,
  CopyAllOutlined,
  CreateOutlined,
  DeleteOutline,
  SettingsOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

type Props = {
  data: TProject[];
};

export const ProjectsCards = ({ data }: Props) => {
  const navigate = useNavigate();

  function handleSelectionModelChange(selectionModel: any) {
    // console.log(selectionModel);
  }

  function handleRowClick(params: any) {
    console.log(params.row);
  }

  function handleGenerateKey(data: TProject) {
    const key = uuidv4();

    console.log(key);
  }
  return (
    <Box>
      {data.map((project) => (
        <Card
          key={project.id}
          sx={{ mb: 2 }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <Avatar sx={{ width: 40, height: 40, mr: 2, color: "white" }}>
                  {project.name[0]}
                </Avatar>
                <Box>
                  <Typography
                    sx={{ color: "text.primary" }}
                    variant="h4"
                  >
                    {project.name}
                  </Typography>
                  <Typography
                    sx={{ color: "text.secondary" }}
                    variant="body1"
                    gutterBottom
                  >
                    {project.url}
                  </Typography>
                  <Typography
                    sx={{ color: "text.secondary" }}
                    variant="body1"
                  >
                    {project.description}
                  </Typography>

                  <Card
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={() => navigator.clipboard.writeText(project.id)}
                  ></Card>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}
            >
              <Tooltip title="Copy key">
                <Button
                  sx={{ p: 1, display: "flex", alignItems: "center" }}
                  onClick={() => navigator.clipboard.writeText(project.id)}
                  endIcon={<ContentCopyOutlined />}
                >
                  KEY: {project.id}
                </Button>
              </Tooltip>
              <Tooltip title="Manage Project">
                <Button
                  onClick={() => navigate(`/dashboard/project/${project.id}`)}
                  startIcon={<SettingsOutlined />}
                >
                  Manage Project
                </Button>
              </Tooltip>
            </Box>
          </CardContent>
        </Card>
      ))}

      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Pagination count={Math.ceil(data.length / 10)} />
      </Box>
    </Box>
  );
};
