import { Box, Button, Container, Stack, Typography } from '@mui/material';
import type { Blog } from '../../lib';
import { DataGrid } from '@mui/x-data-grid';

type Props = {
  list: Blog[];
  onEdit?: (row: Blog) => void;
  onDoubleClick?: (row: Blog) => void;
};

export default function BlogResults({ list, onDoubleClick, onEdit }: Props) {
  function handleRightClick(event: any) {
    console.log(event);
  }

  return (
    <Box>
      <DataGrid
        rows={list}
        columns={[
          { field: 'title', headerName: 'Title', width: 150 },
          {
            field: 'createdAt',
            headerName: 'Created At',
            valueGetter: (value) => new Date(value).toLocaleDateString(),
            width: 150,
          },
          {
            field: 'updatedAt',
            headerName: 'Updated At',
            valueGetter: (value) => new Date(value).toLocaleDateString(),
            width: 150,
          },
          {
            field: 'active',
            headerName: 'Actions',
            width: 150,
            renderCell: ({ row }) => {
              return (
                <Stack alignItems={'center'}>
                  <Button onClick={() => onEdit && onEdit(row)}>Edit</Button>
                </Stack>
              );
            },
          },
        ]}
        pageSizeOptions={[5]}
        onRowDoubleClick={(row) => {
          console.log(row);
          onDoubleClick && onDoubleClick(row.row as Blog);
        }}
      />
    </Box>
  );
}
