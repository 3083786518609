import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { FC } from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { PageLink } from "../custom/PageLink";
import AuthUserBadge from "../user/AuthUserBadge";
import { LoginDrawer } from "../auth/LoginDrawer/LoginDrawer";
import { useAuth } from "../../lib/hooks/useAuth";
import {
  DashboardCustomizeOutlined,
  MenuOpenOutlined,
  MenuOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  selectSideDrawerIsOpen,
  setAuthDrawerIsOpen,
  setSideDrawerIsOpen,
} from "../../store/reducers";
import { useSelector } from "react-redux";

export const Header: FC = () => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const isSideNavVisible = useSelector(selectSideDrawerIsOpen);

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "background.default",
        color: "text.primary",
        zIndex: (theme) => theme.zIndex.drawer - 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        boxShadow: "none",
      }}
      variant="elevation"
    >
      <Container>
        <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
          <Button
            variant="text"
            color="inherit"
            sx={{ display: { xs: "flex", xl: "none" } }}
            size="medium"
            onClick={() => dispatch(setSideDrawerIsOpen(!isSideNavVisible))}
          >
            {isSideNavVisible ? <MenuOpenOutlined /> : <MenuOutlined />}
          </Button>
          <PageLink
            href="/"
            color={"inherit"}
            key={"home"}
          >
            <Typography
              sx={{
                flexGrow: 1,
                fontSize: "1.2rem",
                fontWeight: "400",
                color: "primary.main",
              }}
            >
              nadhirsha.com
            </Typography>
          </PageLink>
          <Box sx={{ ml: "auto" }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
            >
              {isAuthenticated && [
                <Link
                  key={"dashboard"}
                  variant="button"
                  href="/dashboard"
                  underline="none"
                  sx={{ color: "inherit", display: "flex" }}
                >
                  <DashboardCustomizeOutlined />
                  <Typography
                    sx={{ ml: 1, display: { xs: "none", md: "block" } }}
                  >
                    Dashboard
                  </Typography>
                </Link>,
              ]}
              <AuthUserBadge />
            </Stack>
          </Box>
        </Box>
      </Container>
      <LoginDrawer />
    </AppBar>
  );
};

export default Header