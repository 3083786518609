import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { TOrganization } from "../../lib";
import type { RootState } from "../store";

export interface OrganizationState {
  organizations: TOrganization[] | [];
}

const initialState: OrganizationState = {
  organizations: [],
};

export const OrganizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<TOrganization[]>) => {
      state.organizations = action.payload ?? [];
    },
  },
});

export const { setOrganizations } = OrganizationSlice.actions;

export const selectOrganizations = (state: RootState) => {
  return state.organization.organizations;
};

export const selectOrganizationById = (id: string) => (state: RootState) => {
  return state.organization.organizations?.find(
    (organization) => organization.id === id
  );
};

export default OrganizationSlice.reducer;
