import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { TProject } from "../../lib";
import type { RootState } from "../store";

export interface ProjectsState {
  projects: TProject[] | [];
}

const initialState: ProjectsState = {
  projects: [],
};

export const ProjectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<TProject[]>) => {
      state.projects = action.payload ?? [];
    },
  },
});

export const { setProjects } = ProjectsSlice.actions;

export const selectProjects = (state: RootState) => {
  return state.projects.projects;
};

export const selectProjectById = (id: string) => (state: RootState) => {
  return state.projects.projects?.find((item) => item.id === id);
};

export default ProjectsSlice.reducer;
