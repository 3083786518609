import { PaletteMode, ThemeOptions, createTheme } from "@mui/material";
import { cyan, grey, blueGrey, deepOrange } from "@mui/material/colors";

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          // primary: amber,
          // divider: amber[200],
          // text: {
          //   primary: grey[900],
          //   secondary: grey[800],
          // },
          background: {
            default: grey[100],
            paper: grey[50],
          },
        }
      : {
          // palette values for dark mode
          // divider: cyan[700],
          background: {
            default: grey[900],
            paper: grey[900],
          },
          // text: {
          //   primary: "#fff",
          //   secondary: grey[50],
          // },
        }),
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "1.6rem",
      fontWeight: 300,
    },
    h2: {
      fontSize: "1.4rem",
      fontWeight: 300,
    },
    h3: {
      fontSize: "1.2rem",
      fontWeight: 300,
    },
    h4: {
      fontSize: "1.1rem",
      fontWeight: 300,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 300,
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: 300,
    },
    body1: {
      fontSize: "0.9rem",
      fontWeight: 300,
    },
    body2: {
      fontSize: "0.8rem",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
      styleOverrides: {},
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        fontWeight: 400,
      },
    },
    MuiTypography: {
      defaultProps: {},
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          boxShadow:
            mode === "light"
              ? "0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
              : "0px 0px 10px 0px rgba(255, 255, 255, 0.1)",
          // borderRadius: "0.5rem",
          borderColor: "transparent !important",
          outline: "none !important",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // variant: "outlined",
        // size: "small",
        color: "inherit",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "gray",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
    },
    MuiMenuItem: {
      defaultProps: {},
      styleOverrides: {
        root: {
          fontSize: "0.9rem",
        },
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: 1,
        direction: "row",
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
  },
});

// const theme = createTheme({
//   palette: {
//     // primary: {
//     //   main: "#d21936",
//     // },
//     text: {
//       primary: "#555",
//       secondary: "#888",
//       disabled: "#aaa",
//     },
//     background: {
//       default: "#f5f5f5",
//       paper: "#fff",
//     },
//   },
//   typography: {
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(","),
//     h3: {
//       fontWeight: 200,
//     },
//     body2: {
//       fontSize: "0.8rem",
//     },
//   },
//   components: {
//     MuiContainer: {
//       defaultProps: {
//         maxWidth: "lg",
//       },
//       styleOverrides: {},
//     },
//     MuiLink: {
//       defaultProps: {
//         underline: "none",
//         fontWeight: 400,
//       },
//     },
//     MuiTypography: {
//       defaultProps: {
//         color: "text.primary",
//       },
//     },
//     MuiCard: {
//       defaultProps: {
//         variant: "outlined",
//       },
//     },
//     MuiButton: {
//       defaultProps: {
//         // variant: "outlined",
//         // color: "primary",
//         // size: "small",
//       },
//     },
//     MuiTextField: {
//       defaultProps: {
//         variant: "outlined",
//         size: "small",
//       },
//     },
//     MuiMenuItem: {
//       defaultProps: {},
//       styleOverrides: {
//         root: {
//           fontSize: "0.9rem",
//         },
//       },
//     },
//     MuiStack: {
//       defaultProps: {
//         spacing: 1,
//         direction: "row",
//       },
//     },
//   },
// });

// export default theme;
