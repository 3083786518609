import { Container } from "@mui/system";
import { HomeServiceCards } from "./components/HomeServiceCards";
import { HomeExplainJobPortal } from "./components/HomeExplainJobPortal";
import { Box } from "@mui/material";
import { HomeLandingHero } from "./components/HomeLandingHero";
import { useAuth } from "../../lib";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Home = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  console.log({ isAuthenticated });

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated]);

  return (
    <div>
      <Container>
        <HomeLandingHero />
      </Container>
    </div>
  );
};

export default Home;
