import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  useMediaQuery,
  type Theme,
} from "@mui/material";
import { PageLink } from "../custom/PageLink";
import {
  AccountBalanceOutlined,
  DocumentScannerOutlined,
  DomainOutlined,
  HomeOutlined,
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
  WebhookOutlined,
  WorkOutline,
  WorkOutlined,
} from '@mui/icons-material';
import { useState } from 'react';
import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import {
  selectSideDrawerIsOpen,
  setSideDrawerIsOpen,
} from '../../store/reducers';
import { useSelector } from 'react-redux';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.main,
    // color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },

  [theme.breakpoints.down('md')]: {},
}));

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: 'unset',
  color: grey[700],

  [theme.breakpoints.down('md')]: {},
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  width: 'max-content',
  color: grey[700],
  [theme.breakpoints.down('md')]: {},
}));

export const listMenu = [
  // {
  //   title: "Home",
  //   link: "/dashboard",
  //   icon: HomeOutlined,
  // },
  {
    title: 'Jobs',
    link: '/dashboard/jobs',
    icon: WorkOutline,
  },
  {
    title: 'Organizations',
    link: '/dashboard/organizations',
    icon: DomainOutlined,
  },
  {
    title: 'Projects',
    link: '/dashboard/projects',
    icon: WebhookOutlined,
  },
  {
    title: 'Blogs',
    link: '/dashboard/blog',
    icon: DocumentScannerOutlined,
  },
];

export default function AdminSideNavigation() {
  const dispatch = useDispatch();
  const isSideNavVisible = useSelector(selectSideDrawerIsOpen);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );

  const handleShowLabelToggle = () => {
    dispatch(setSideDrawerIsOpen(!isSideNavVisible));
  };

  return (
    <Box
      sx={{
        p: 0,
        position: { xs: "absolute" },
        left: 0,
        zIndex: 1,
      }}
    >
      <List
        disablePadding
        sx={{ bgcolor: "background.paper" }}
      >
        {listMenu.map((item) => (
          <PageLink
            key={item.title}
            href={item.link}
          >
            <StyledListItem>
              <StyledListItemAvatar>
                <item.icon />
              </StyledListItemAvatar>
              {isSideNavVisible && <StyledListItemText primary={item.title} />}
            </StyledListItem>
          </PageLink>
        ))}
        {!isMobile && (
          <StyledListItem onClick={handleShowLabelToggle}>
            <StyledListItemAvatar>
              {isSideNavVisible ? (
                <KeyboardDoubleArrowLeftOutlined />
              ) : (
                <KeyboardDoubleArrowRightOutlined />
              )}
            </StyledListItemAvatar>
            {isSideNavVisible && <StyledListItemText primary="Hide Sidebar" />}
          </StyledListItem>
        )}
      </List>
    </Box>
  );
}
