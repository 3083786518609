import { ArrowBackOutlined, DomainAddOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { OrganizationsTable } from "./components/OrganizationsTable";
import { OrganizationForm } from "./components/OrganizationForm";
import { useEffect, useState } from "react";
import { FAQOrganization } from "./components/FAQOrganization";
import { useOrganization } from "../../lib";
import { useNavigate } from "react-router-dom";

const Header = (
  <div>
    <Typography
      variant="h1"
      gutterBottom
    >
      Organizations
    </Typography>
    <Typography variant="body1">
      Organizations are entities in the system where other entities can be
      associated. This can be used to manage and generate reports based on the
      registered organizations
    </Typography>
  </div>
);

export const OrganizationLanding = () => {
  const [showForm, setShowForm] = useState(false);
  const { organizations, getAllOrganizations } = useOrganization();
  const navigate = useNavigate();

  useEffect(() => {
    getAllOrganizations();
  }, []);

  return (
    <div>
      <Button
        startIcon={<ArrowBackOutlined />}
        color="inherit"
        onClick={() => navigate("/dashboard")}
      >
        Back to Dashboard
      </Button>
      {Header}
      <Grid
        container
        sx={{ my: 2 }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={9}
        >
          <OrganizationsTable data={organizations} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
        >
          <Card>
            <CardContent>
              <Typography
                variant="h3"
                gutterBottom
              >
                Manage Organizations
              </Typography>

              <List autoFocus={false}>
                <ListItemButton onClick={() => setShowForm(true)}>
                  <ListItemIcon>
                    <DomainAddOutlined />
                  </ListItemIcon>
                  Add Organization
                </ListItemButton>
              </List>
            </CardContent>
          </Card>
          <Box sx={{ mt: 2 }}>
            <FAQOrganization />
          </Box>
        </Grid>
      </Grid>

      {showForm && <OrganizationForm onClose={() => setShowForm(false)} />}
    </div>
  );
};
