import {
  Box,
  Button,
  Container,
  Drawer,
  LinearProgress,
  Modal,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import useBlogs from '../../lib/hooks/useBlogs';
import BlogResults from '../../components/blog/BlogResults';
import { useState } from 'react';
import { Blog } from '../../lib';
import { SideDrawer } from '../../components/UI/SideDrawer';
import BlogForm from '../../components/blog/BlogForm';
import { RawHtml } from '../../components/textEditor/RawHtml';

export default function BlogLanding() {
  const { blogs, loading, createBlog, updateBlog } = useBlogs();
  const [selectedBlog, setSelectedBlog] = useState<Blog>();
  const [openForm, setOpenForm] = useState(false);
  const [openView, setOpenView] = useState(false);

  function handleDoubleClick(blog: Blog) {
    setSelectedBlog(blog);
    setOpenView(true);
  }

  function handleCreate(blog: Partial<Blog>) {
    blog.id ? updateBlog(blog) : createBlog(blog);
    setOpenForm(false);
  }

  function handleEdit(blog: Blog) {
    setSelectedBlog(blog);
    setOpenForm(true);
  }

  function handleClose() {
    setSelectedBlog(undefined);
    setOpenView(false);
    setOpenForm(false);
  }

  return (
    <Container>
      <Typography variant='h1'>Blogs</Typography>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'flex-end'}
      >
        <Button onClick={() => setOpenForm(true)}>Create Blog</Button>
      </Stack>

      <Box sx={{ mt: 2 }}>
        <LinearProgress sx={{ display: loading ? 'block' : 'none' }} />
        <BlogResults
          list={blogs}
          onDoubleClick={handleDoubleClick}
          onEdit={handleEdit}
        />
      </Box>

      {openView && selectedBlog && (
        <SideDrawer
          open
          onClose={handleClose}
          width={'50%'}
        >
          <Box>
            <Typography variant='h2'>{selectedBlog.title}</Typography>
            <RawHtml html={selectedBlog.content} />
          </Box>
        </SideDrawer>
      )}

      {openForm && (
        <SideDrawer
          open
          onClose={handleClose}
          width={'50%'}
        >
          <BlogForm
            onSubmit={handleCreate}
            blog={selectedBlog}
          />
        </SideDrawer>
      )}
    </Container>
  );
}
