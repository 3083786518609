import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAuth } from "../../lib/hooks/useAuth";
import { useContext, useState } from "react";
import {
  DarkModeOutlined,
  DashboardCustomizeOutlined,
  LoginOutlined,
  SupervisedUserCircleOutlined,
  WbSunnyOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { routes } from "../../lib/constants/routes";
import { useDispatch } from "react-redux";
import { setAuthDrawerIsOpen } from "../../store/reducers";
import { colorModeContext } from "../../App";

export function AuthUserBadge() {
  const { user, logoutUser, isAuthenticated, validDateDisplay } = useAuth();
  const colorMode = useContext(colorModeContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    // navigate(routes.login);
    dispatch(setAuthDrawerIsOpen(true));
  };

  if (!isAuthenticated || !user) {
    return (
      <Tooltip title="Login">
        <IconButton onClick={handleLogin}>
          <LoginOutlined />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Box>
      <Tooltip title={user.name}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: "primary.main" }}>
            {user.name[0]}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Box sx={{ minWidth: 200 }}>
            <Typography variant="body1">{user.name}</Typography>
            <Typography variant="body2">{user.email}</Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={colorMode.toggleColorMode}>
          {localStorage.getItem("mode") === "dark" ? (
            <WbSunnyOutlined fontSize="small" />
          ) : (
            <DarkModeOutlined fontSize="small" />
          )}

          <Typography sx={{ ml: 1 }}>
            {localStorage.getItem("mode") === "dark" ? "Light" : "Dark"} Mode
          </Typography>
          {/* <Switch
            key={"switch-mode"}
            onChange={colorMode.toggleColorMode}
            checked={localStorage.getItem("mode") === "light"}
            color="default"
          /> */}
        </MenuItem>
        <Divider />
        <MenuItem>
          <Box>
            <Typography variant="body2">Token valid until</Typography>
            <Typography sx={{ display: { xs: "none", md: "block" } }}>
              {validDateDisplay}
            </Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem>
          <SupervisedUserCircleOutlined fontSize="small" />
          <Typography sx={{ ml: 1 }}>Profile</Typography>
        </MenuItem>
        <MenuItem onClick={() => navigate(routes.dashboard)}>
          <DashboardCustomizeOutlined fontSize="small" />
          <Typography sx={{ ml: 1 }}>Dashboard</Typography>
        </MenuItem>
        <MenuItem onClick={logoutUser}>
          <LoginOutlined fontSize="small" />
          <Typography sx={{ ml: 1 }}>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default AuthUserBadge;
