import { useEffect, useState } from 'react';
import { bbBackendApi } from '../api/api';
import { routes } from '../constants/routes';
import { apiRoutes } from '../constants/apiRoutes';
import type { Blog } from '../types';

export default function useBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  function fetchBlogs() {
    bbBackendApi.get(`${apiRoutes.blog}?includeInactive=true`).then((res) => {
      setBlogs(res.data);
      setLoading(false);
    });
  }

  function createBlog(blog: Partial<Blog>) {
    bbBackendApi.post(apiRoutes.blog, blog).then(() => {
      fetchBlogs();
    });
  }

  function updateBlog(blog: Partial<Blog>) {
    bbBackendApi.put(`${apiRoutes.blog}/${blog.id}`, blog).then(() => {
      fetchBlogs();
    });
  }

  function deleteBlog(id: string) {
    bbBackendApi.delete(`${apiRoutes.blog}/${id}`).then(() => {
      fetchBlogs();
    });
  }

  useEffect(() => {
    fetchBlogs();
  }, []);

  return { blogs, loading, createBlog, updateBlog, deleteBlog };
}
