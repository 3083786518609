import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface AppState {
  authDrawerIsOpen?: boolean;
  sideDrawerIsOpen?: boolean;
}

const initialState: AppState = {
  authDrawerIsOpen: false,
  sideDrawerIsOpen: false,
};

export const AppStateSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAuthDrawerIsOpen: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.authDrawerIsOpen = action.payload;
    },

    setSideDrawerIsOpen: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.sideDrawerIsOpen = action.payload;
    },
  },
});

export const { setAuthDrawerIsOpen, setSideDrawerIsOpen } =
  AppStateSlice.actions;

export const selectAuthDrawerIsOpen = (state: RootState) => {
  return state.appState.authDrawerIsOpen;
};

export const selectSideDrawerIsOpen = (state: RootState) => {
  return state.appState.sideDrawerIsOpen;
};

export default AppStateSlice.reducer;
