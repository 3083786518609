import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
  type TextFieldProps,
} from "@mui/material";
import { SideDrawer } from "../../../components/UI/SideDrawer";
import {
  CheckOutlined,
  CloseOutlined,
  type BorderBottom,
  type BorderColor,
} from "@mui/icons-material";
import { FAQOrganization } from "./FAQOrganization";
import * as yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useOrganization } from "../../../lib";

const StyledTextField = styled(TextField)(({ theme }) => ({
  //change border color of the starndard variant text field of mui
  "& .MuiInputBase-input": {
    BorderBottom: `1px solid ${theme.palette.secondary.main} !important`,
  },
}));

StyledTextField.defaultProps = {
  variant: "standard",
  fullWidth: true,
} as TextFieldProps;

const validationSchema = yup.object({
  name: yup.string().required("Required"),
  description: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),
  phone: yup
    .number()
    .typeError("Must be a number")
    .min(10, "Min be 10 digits")
    .required("Required"),
  address: yup.string().required("Required"),
  website: yup.string().required("Required"),
  contactPerson: yup.string().required("Required"),
});

type Props = {
  onClose: () => void;
};
export const OrganizationForm = ({ onClose }: Props) => {
  const { createOrganization } = useOrganization();
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      email: "",
      phone: "",
      address: "",
      website: "",
      contactPerson: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      createOrganization(values)
        .then((response) => {
          console.log(response);
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    document.title = "Add new organization - Blood Bank";
  }, []);

  return (
    <SideDrawer
      open={true}
      onClose={onClose}
      anchor="bottom"
      width={"100%"}
    >
      <Box>
        <Typography variant="h1">Add new organization</Typography>
        <Typography variant="body1">
          Fill the form below to add a new organization
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={8}
            >
              <form onSubmit={formik.handleSubmit}>
                <Box paddingRight={2}>
                  <Box sx={{ height: "60vh" }}>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                      >
                        <StyledTextField
                          label="Name"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                      >
                        <StyledTextField
                          label="Description"
                          multiline
                          rows={4}
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                          }
                          helperText={
                            formik.touched.description &&
                            formik.errors.description
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                      >
                        <StyledTextField
                          multiline
                          rows={4}
                          label="Address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.address &&
                            Boolean(formik.errors.address)
                          }
                          helperText={
                            formik.touched.address && formik.errors.address
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <StyledTextField
                          label="Contact Person"
                          name="contactPerson"
                          value={formik.values.contactPerson}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.contactPerson &&
                            Boolean(formik.errors.contactPerson)
                          }
                          helperText={
                            formik.touched.contactPerson &&
                            formik.errors.contactPerson
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <StyledTextField
                          label="Phone"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <StyledTextField
                          label="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <StyledTextField
                          label="Website"
                          name="website"
                          value={formik.values.website}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.website &&
                            Boolean(formik.errors.website)
                          }
                          helperText={
                            formik.touched.website && formik.errors.website
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      ></Grid>
                    </Grid>
                  </Box>
                  <Stack justifyContent={"end"}>
                    <Button startIcon={<CloseOutlined />}>Cancel</Button>
                    <Button
                      type="submit"
                      startIcon={<CheckOutlined />}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>
              </form>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
            >
              <Box sx={{ borderLeft: "1px solid #e0e0e0", paddingLeft: 4 }}>
                <FAQOrganization />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 4 }}></Box>
      </Box>
    </SideDrawer>
  );
};
