import { ArrowBackOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization, useProjects } from "../../lib";
import { useState, type SyntheticEvent } from "react";

export const ProjectDetails = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data } = useProjects({ projectId });

  const [selectedTab, setSelectedTab] = useState(0);

  function handleTabChange(event: SyntheticEvent, item: number) {
    console.log(item);
    setSelectedTab(item);
  }

  if (!data) {
    return (
      <Box>
        <Button
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate("/dashboard/organizations")}
          sx={{ mb: 2 }}
        >
          Back to Projects
        </Button>

        <Typography>Organization not found</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Button
        startIcon={<ArrowBackOutlined />}
        onClick={() => navigate("/dashboard/organizations")}
        sx={{ mb: 2 }}
      >
        Back to Projects
      </Button>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h1">{data.name}</Typography>
        <Typography variant="body1">{data.description}</Typography>
      </Box>

      {/* <Card sx={{ width: "100%" }}>
        <CardContent> */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
      >
        <Tab label="Details" />
        <Tab label="Users" />
      </Tabs>
      <Box sx={{ mt: 4 }}>
        {selectedTab === 0 && (
          <Box>
            <Typography variant="h3">Details</Typography>
            <Typography variant="body1">{data.description}</Typography>
          </Box>
        )}
        {selectedTab === 1 && <Box>Users</Box>}
      </Box>
      {/* </CardContent>
      </Card> */}
    </Box>
  );
};
