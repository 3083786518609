import { Container, ListItemText, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../lib";

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    // display: "none",
  },
}));

export function UserDashboard() {
  const { isAuthenticated } = useAuth();
  return (
    <Container sx={{ py: 2 }}>
      {/* <Box sx={{ display: { xs: "flex", xl: "block" }, gap: 2 }}> */}
      <Outlet />
      {/* </Box> */}
    </Container>
  );
}
