import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import TestSnackbar from "./components/TestSnackbar";
import { TestFileUploader } from "./components/FileUploader";

const TestPage = () => {
  return (
    <Container>
      <Card variant="outlined">
        <CardContent>
          <Typography
            variant="h3"
            gutterBottom
          >
            Test Page
          </Typography>
          <Typography variant="body2">To try everything</Typography>
        </CardContent>
      </Card>
      <Stack
        direction={"column"}
        gap={2}
        sx={{ mt: 2 }}
      >
        <TestSnackbar />
        <TestFileUploader />
      </Stack>
    </Container>
  );
};

export default TestPage;
