import { ArrowBackOutlined, DomainAddOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { ProjectsTable } from "./components/ProjectsTable";
import { ProjectForm } from "./components/ProjectForm";
import { useEffect, useState } from "react";
import { FAQProjects } from "./components/FAQProjects";
import { useOrganization, useProjects } from "../../lib";
import { useNavigate } from "react-router-dom";
import { ProjectsCards } from "./components/ProjectsCards";

const Header = (
  <div>
    <Typography
      variant="h1"
      gutterBottom
    >
      Projects
    </Typography>
    <Typography variant="body1">
      Register your projecets to manage resources specific to them
    </Typography>
  </div>
);

export const ProjectsLanding = () => {
  const [showForm, setShowForm] = useState(false);
  const { list, getAllProjects } = useProjects();
  const navigate = useNavigate();

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div>
      <Button
        startIcon={<ArrowBackOutlined />}
        color="inherit"
        onClick={() => navigate("/dashboard")}
      >
        Back to Dashboard
      </Button>
      {Header}
      <Grid
        container
        sx={{ my: 2 }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={9}
        >
          {/* <ProjectsTable data={list} /> */}
          <ProjectsCards data={list} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
        >
          <Card>
            <CardContent>
              <Typography
                variant="h3"
                gutterBottom
              >
                Manage Projects
              </Typography>

              <List autoFocus={false}>
                <ListItemButton onClick={() => setShowForm(true)}>
                  <ListItemIcon>
                    <DomainAddOutlined />
                  </ListItemIcon>
                  Add Project
                </ListItemButton>
              </List>
            </CardContent>
          </Card>
          <Box sx={{ mt: 2 }}>
            <FAQProjects />
          </Box>
        </Grid>
      </Grid>

      {showForm && <ProjectForm onClose={() => setShowForm(false)} />}
    </div>
  );
};
