import { ArrowBackOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../../lib";
import { useState, type SyntheticEvent } from "react";

export const OrganizationDetails = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { organization } = useOrganization({ organizationId });

  const [selectedTab, setSelectedTab] = useState(0);

  function handleTabChange(event: SyntheticEvent, item: number) {
    console.log(item);
    setSelectedTab(item);
  }

  if (!organization) {
    return (
      <Box>
        <Button
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate("/dashboard/organizations")}
          sx={{ mb: 2 }}
        >
          Back to Organizations
        </Button>

        <Typography>Organization not found</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Button
        startIcon={<ArrowBackOutlined />}
        onClick={() => navigate("/dashboard/organizations")}
        sx={{ mb: 2 }}
      >
        Back to Organizations
      </Button>

      <Card sx={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h1">{organization.name}</Typography>
          <Typography variant="body1">{organization.description}</Typography>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{ mt: 2 }}
          >
            <Tab label="Details" />
            <Tab label="Users" />
          </Tabs>
          <Box sx={{ mt: 4 }}>
            {selectedTab === 0 && (
              <Box>
                <Typography variant="h3">Details</Typography>
                <Typography variant="body1">
                  {organization.description}
                </Typography>
              </Box>
            )}
            {selectedTab === 1 && <Box>Users</Box>}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
