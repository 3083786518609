import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { Box, useMediaQuery, type Theme } from "@mui/material";
import Footer from "./Footer";
import { useAuth } from "../../lib";
import AdminSideNavigation from "./AdminSideNavigation";
import { useSelector } from "react-redux";
import {
  selectSideDrawerIsOpen,
  setSideDrawerIsOpen,
} from "../../store/reducers";
import { useDispatch } from "react-redux";

type Props = {};

export const Layout: FC<Props> = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const isSideNavVisible =
    useSelector(selectSideDrawerIsOpen) && isAuthenticated;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );

  useEffect(() => {
    !isMobile && dispatch(setSideDrawerIsOpen(false));
  }, [isMobile]);
  return (
    <>
      <Header />
      <Box
        sx={{
          mt: 10,
          mb: 2,
          minHeight: "70vh",
          bgcolor: "background.default",
          display: isSideNavVisible ? "flex" : !isMobile ? "flex" : "block",
        }}
      >
        {isAuthenticated && (isSideNavVisible || !isMobile) && (
          <AdminSideNavigation />
        )}
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};
