import { Typography, List, ListItem, ListItemText } from "@mui/material";

export const FAQOrganization = () => {
  return (
    <div>
      <Typography variant="h3">FAQ - Organization</Typography>
      <List draggable>
        <ListItem>
          <ListItemText
            primary="What is Organization"
            secondary="Organization serves as the foundational entity within the app's ecosystem, acting as the central hub for all other registered entities. It provides a structural framework for managing various aspects of the app, including user accounts, permissions, and data organization."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Collaboration Features"
            secondary="Organization Includes collaboration features that allow multiple users within the same organization to work together seamlessly. This may involve shared calendars, document sharing, or task assignment functionalities.."
          />
        </ListItem>
      </List>
    </div>
  );
};
