import { useState } from "react";
import { bbBackendApi } from "../api/api";

type FileUploaderHook = {
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  uploadFile: () => void;
};

const useFileUploader = (): FileUploaderHook => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const uploadFile = () => {
    // Implement your file upload logic here
    if (selectedFile) {
      // Example: Use fetch API to upload the file
      const formData = new FormData();
      formData.append("file", selectedFile);

      bbBackendApi.post("api/files/upload", formData);
    }
  };

  return {
    selectedFile,
    setSelectedFile,
    uploadFile,
  };
};

export default useFileUploader;
