import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import type { Blog } from '../../lib';
import { CustomTextEditor } from '../textEditor/CustomTextEditor';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  content: yup.string().required('Content is required'),
});

type Props = {
  blog?: Blog;
  onSubmit: (values: Partial<Blog>) => void;
};

export default function BlogForm({ blog, onSubmit }: Props) {
  const formik = useFormik({
    initialValues: {
      title: blog?.title || '',
      content: blog?.content || '',
      active: blog?.active || false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      onSubmit(blog ? { ...blog, ...values } : values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant='h1'>Create Blog</Typography>
      <Stack
        direction={'column'}
        sx={{ p: 2 }}
      >
        <TextField
          fullWidth
          label='Title'
          id='title'
          name='title'
          value={formik.values.title}
          onChange={formik.handleChange}
          helperText={formik.touched.title && formik.errors.title}
          error={formik.touched.title && Boolean(formik.errors.title)}
        />
        {/* <TextField
          fullWidth
          multiline
          rows={10}
          label='Content'
          id='content'
          name='content'
          value={formik.values.content}
          onChange={formik.handleChange}
          helperText={formik.touched.content && formik.errors.content}
          error={formik.touched.content && Boolean(formik.errors.content)}
        /> */}
        <CustomTextEditor
          title='Content'
          value={formik.values.content}
          onChange={(data) => formik.setFieldValue('content', data)}
          error={formik.touched.content && Boolean(formik.errors.content)}
          helperText={formik.touched.content && formik.errors.content}
        />
        <FormGroup>
          <Typography>
            Active
            <Checkbox
              value={formik.values.active}
              checked={formik.values.active}
              onChange={formik.handleChange}
              name='active'
            />
          </Typography>
        </FormGroup>
        <Button type='submit'>Submit</Button>
      </Stack>
    </form>
  );
}
